import React from 'react';
import styled from 'styled-components';
import { CustodyClickableText, CustodyMainText, CustodyStyledTable } from './CustodyStyles';
import usePermissions from 'shared/usePermissions';
import { useNavigate } from 'react-router-dom';
import useInvest from './invest/useInvest';
import { DefiBalanceRow } from 'state/store/investApi';
import { Info, QuestionCircle } from 'assets/icons';
import getUsDollar from 'shared/dollarFormat';
import { Percentage } from 'lib/utils/types';
import Tooltip from 'components/atoms/Tooltip';
import InkindAssetRenderer from "../../atoms/asset/InkindAssetRenderer";
import CustodyRateOfReturns from './CustodyRateOfReturns'
import { isNegative } from 'utils/isNegative';
import Loading from 'components/atoms/Loading';
import { SpinnerSizeEnum } from '@types/enums/Loading.enum';


interface CustodyInvestmentSummaryProps {
  includeAllBalances?: boolean;
}

const headerData = [
  { label: 'Strategy' },
  { label: 'Current Yield', tooltip: 'Expected yield at this time' },
  { label: 'Historical Yield' },
  { label: 'Rate of Return' },
  { label: 'Current Balance' },
  { label: 'Income (YTD)' },
  { label: 'Cost Basis' },
  { label: 'Realized G/L (YTD)' },
  { label: 'Unrealized G/L', tooltip: 'Any unrealized gain/loss prior to Abra deposit is not accounted for', hasInfoIcon: true },
  { label: 'Actions' },
];

const CustodyInvestmentSummary: React.FC<CustodyInvestmentSummaryProps> = ({ includeAllBalances }) => {
  const invest = useInvest();
  const balances = invest.defiBalances.data
    ?.filter(b => includeAllBalances || b.currentBalance) ?? [];
  return (
    <Container>
      <TokenSummaryTitle>Investment Details</TokenSummaryTitle>
      {invest.defiBalances.isLoading && <Loading size={SpinnerSizeEnum.LARGE} showText={false} />}
      {!invest.defiBalances.isLoading && balances.length === 0 && <CustodyMainText>You don't have any investments. Please go to the invest tab to learn more about the strategies Abra offers</CustodyMainText>}
      {balances.length > 0 && 
      <CustodyStyledTable>
        <thead>
          <tr>
            {headerData.map((header, index) => (
              header.tooltip ? (
                <Tooltip key={index} placement='top' text={header.tooltip}>
                  <th>
                    {header.label}
                    {header.hasInfoIcon && <Info color='#fffa' size={15} />}
                  </th>
                </Tooltip>
              ) : (
                <th key={index}>
                  {header.label}
                </th>
              )
            ))}
          </tr>
        </thead>
        <tbody>
          {balances?.map((record, index) => (
            <CustodySummaryRow key={index} record={record} />
          ))}
        </tbody>
      </CustodyStyledTable>
      }
    </Container>
  );
};

interface CustodySummaryRowProps {
  record: DefiBalanceRow;
}
const CustodySummaryRow: React.FC<CustodySummaryRowProps> = ({ record }) => {
  const { canInvest, canDivest } = usePermissions();
  const navigate = useNavigate();

  const goToStrategyDetails = (strategyIdentifier: string) => {
    navigate(`/custody/invest/details/${strategyIdentifier}`);
  };
  const goToInvest = (strategyIdentifier: string) => {
    navigate(`/custody/invest/${strategyIdentifier}`);
  };
  const goToDivest = (strategyIdentifier: string) => {
    navigate(`/custody/divest/${strategyIdentifier}`);
  };

  const renderInvestButton = canInvest && record.canInvest;
  const renderDivestButton = canDivest && record.canDivest && !!record.currentBalance;

  return (
    <tr>
      <td onClick={() => goToStrategyDetails(record.identifier)}>
        <CustodyClickableText>{record.strategyName}</CustodyClickableText>
      </td>
      <td>
        <CustodyMainText>
          {record.expectedAPR && <CustodyRateOfReturns defiBalanceRow={record} type="Yield" />}
          {!record.expectedAPR && <>--</>}
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText>
          {!record.expectedAPR && <CustodyRateOfReturns defiBalanceRow={record} type="HistoricalYield" />}
          {record.expectedAPR && <>--</>}
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText>
          <CustodyRateOfReturns defiBalanceRow={record} type='Return'/>
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText>
          <InkindAssetRenderer
              balance={record.currentBalance}
              value={record.currentValue}
          />
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText>
          <InkindAssetRenderer
            balance={record.yieldYTD}
            value={record.incomeYTD}
          />
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText isNegative={isNegative(record.costBasis?.amount)}>{getUsDollar(record.costBasis?.amount ?? '0')}</CustodyMainText>
      </td>
      <td>
        <GainLossArea loss={parseFloat(record.realizedYTD?.amount ?? '0') < 0}>
          <CustodyMainText isNegative={isNegative(record.realizedYTD?.amount)}>{getUsDollar(record.realizedYTD?.amount ?? '0')}</CustodyMainText>
        </GainLossArea>
      </td>
      <td>
        <GainLossArea loss={parseFloat(record.unrealizedAmount?.amount ?? '0') < 0}>
          <CustodyMainText isNegative={isNegative(record.unrealizedAmount?.amount)}>{getUsDollar(record.unrealizedAmount?.amount ?? '0')}</CustodyMainText>
        </GainLossArea>
      </td>
      <td>
        <ButtonsArea>
          {renderInvestButton && (
            <ActionButton onClick={() => goToInvest(record.identifier)}>
              <span>Invest</span>
            </ActionButton>
          )}
          {renderDivestButton && (
            <ActionButton onClick={() => goToDivest(record.identifier)}>
              <span>Divest</span>
            </ActionButton>
          )}
        </ButtonsArea>
      </td>
    </tr>
  );
};

interface GainLossProps {
  loss: boolean;
}
const GainLossArea = styled.span<GainLossProps>`
  ${(p) => (p.loss ? 'color: #EC396B;' : '')}
`;
const UnrealizedGainLossTitle = styled.th`
  display: flex;
  align-items: center;
  gap: 3px;
  cursor: pointer;
`;
const HoverArea = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;
const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #6f2acd;
  border-radius: 0.5vw;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  font-size: 70%;
  padding: 4px 1vw;
  transition: background-color 0.3s ease-in;
  cursor: pointer;
  &:hover {
    background-color: #292749;
  }
`;
const ButtonsArea = styled.div`
  display: flex;
  gap: 0.5vw;
`;
const Container = styled.div`
`
const TokenSummaryTitle = styled.div`
  font-weight: 600;
  color: #ffffff;
  font-size: 18px;
  letter-spacing: -0.5px;
  margin-bottom: 6px;
`;
export default CustodyInvestmentSummary;