export const privacyPolicyURL = "https://www.abra.com/privacy-policy/";

export const termsOfServiceURL = "https://www.abra.com/terms-of-service/";

export const supportURL = "https://support.abra.com/hc/en-us";

export const coinMarketAssetsImages = (assetId: string) => `https://s2.coinmarketcap.com/static/img/coins/32x32/${assetId}.png`;

export const simplex = '//goabra.staging.wpengine.com/simplex/?crypto=BTC&fiat=USD&ref_id=abra-app';

export const verifiedInvestorURL = 'https://support.abra.com/hc/en-us/articles/8762273279515-How-can-I-get-verified-as-an-accredited-investor-with-Abra-';

export const wireInformationURL = 'https://support.abra.com/hc/en-us/articles/29470845005595-How-do-I-wire-funds-into-my-Abra-account'