import { emptySplitApi as api } from './emptyApi';

export const BOOST_WITHDRAW = 'BOOST_WITHDRAW'
export const CUSTODY_WITHDRAW = 'CUSTODY_WITHDRAW'
export const CUSTODY_DEPOSIT = 'CUSTODY_DEPOSIT'
export const CUSTODY_INVEST = 'CUSTODY_INVEST'
export const CUSTODY_DEPOSIT_FIAT = 'CUSTODY_DEPOSIT_FIAT'
export const CUSTODY_TRADE = 'CUSTODY_TRADE'
export const CUSTODY_BORROW = 'CUSTODY_BORROW'
export const CUSTODY_WITHDRAW_FIAT = 'CUSTODY_WITHDRAW_FIAT'
export const CUSTODY_SETTINGS = 'PRIME_API_KEYS'
export const PRIME_USER_MANAGEMENT = 'PRIME_USER_MANAGEMENT'

export const possibleFeatureFlags = [
  BOOST_WITHDRAW , 
  CUSTODY_WITHDRAW , 
  CUSTODY_INVEST , 
  CUSTODY_DEPOSIT,
  CUSTODY_DEPOSIT_FIAT,
  CUSTODY_TRADE, 
  CUSTODY_BORROW,
  CUSTODY_WITHDRAW_FIAT,
  CUSTODY_SETTINGS,
  PRIME_USER_MANAGEMENT
] as const
export type FeatureFlag = typeof possibleFeatureFlags[number]


export const withdrawApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDepositsForWithdraw: build.query<GetDepositsForWithdrawInterface, null>({
      query: () => ({ url: `/order/deposits-withdraw` }),
    }),
    getNetworks: build.query<GetNetworksResponse, string>({
      query: (asset) => ({ url: `/withdraw/${asset}/networks` }),
    }),
    getWhitelistAddresses: build.query<GetTransferAddressesResponse, null>({
      query: () => ({ url: '/whitelist/current-organization' }),
    }),
    createAddress: build.mutation<TransferAddress, TransferAddress>({
      query: (transferAddress) => ({ url: '/whitelist', method: 'POST', body: transferAddress }),
    }),
    deleteAddress: build.mutation<void, number>({
      query: (id) => ({ url: `/whitelist/${id}`, method: 'DELETE' }),
    }),
    createWithdrawRequest: build.mutation<WithdrawRequest, WithdrawRequest>({
      query: (withdrawRequest) => ({ url: `/withdraw/request`, method: 'POST', body: withdrawRequest }),
    }),
    sendEmailVerification: build.mutation<WithdrawRequest, string>({
      query: (withdrawRequestId) => ({ url: `/withdraw/email-start/${withdrawRequestId}`, method: 'POST'})
    }),
    withdrawRequestPasswordValidation: build.mutation<AccessTokenWrapper, OtpStartValidation>({
      query: (otpStart) => ({ url: `/withdraw/otp-start`, method: 'POST', body: otpStart }),
    }),
    withdrawRequestOtpValidation: build.mutation<void, OtpValidation>({
      query: (otpValidate) => ({ url: `/withdraw/otp-validate/${otpValidate.withdrawRequestId}`, method: 'POST', body: otpValidate.validation }),
    }),
    withdrawRequestEmailValidation: build.mutation<void, EmailValidation>({
      query: (emailValidate) => ({ url: `/withdraw/email-validate/${emailValidate.withdrawRequestId}`, method: 'POST', body: { code: emailValidate.code } }),
    }),
    getOngoingWithdrawRequests: build.query<GetOngoingProps, void | null>({
      query: () => ({ url: '/withdraw/uncompleted' }),
    }),
    setAddressAsVerified: build.mutation<void, TransferAddress>({
      query: (address) => ({ url: `/whitelist/verified/${address.id}`, method: 'PATCH', body: { verified: true } }),
    }),
    getCompletedTransactions: build.query<GetCompletedTransactionsResponse, number>({
      query: (addressId) => ({ url: `/whitelist/${addressId}/withdraw/completed` }),
    }),
    getNetworksApprovedForAsset: build.query<NetworkAssetPairResponse, string>({
      query: (asset) => ({ url: `/withdraw/${asset}/approved-networks` }),
    }),
    getTransactionDetails: build.query<TransactionDetailsResponse, number>({
      query: (operationId) => ({ url: `/transaction/operation/${operationId}` }),
    }),
    getDailyLimit: build.query<string, string>({
      query: (asset) => ({ url: `/withdraw-approval/condition/asset/${asset}` }),
    }),
    isFeatureEnabled: build.query<EnabledResponse, FeatureFlag>({
      query: (flag) => ({ url: `/feature?featureFlag=${flag}` }),
    }),
    setCheckTermsService: build.mutation<void, boolean>({
      query: (checked) => ({ url: '/tos/check', method: 'POST', body: { checked } }),
    }),
    getTermsServiceChecked: build.query<CheckPayload, void>({
      query: () => ({ url: '/tos/check' }),
    }),
  }),
  overrideExisting: false,
});

export interface EnabledResponse {
  enabled: boolean;
}

export interface TermsResponse {
  checked: boolean;
}
export interface CheckPayload {
  checked: boolean;
}
export interface GetCompletedTransactionsResponse {
  withdrawRequests: WithdrawRequest[];
}
export interface GetOngoingProps {
  withdrawRequests: WithdrawRequest[];
}
export interface NetworkAssetPairResponse {
  networks: string[];
}
export interface AccessTokenWrapper {
  accessToken: string;
}
export interface EmailValidation {
  code: string;
  withdrawRequestId: number;
}
export interface OtpValidation {
  validation: {
    mfaToken: string;
    otpCode: string;
  };
  withdrawRequestId: number;
}
export interface OtpStartValidation {
  username: string;
  password: string;
}
export interface WithdrawRequest {
  id?: number;
  amount: string;
  whitelistAddressId: number;
  orderId: number;
  currency?: string;
  status?: string;
  transactionId?: number;
  externalRequestUid?: string
}
interface GetTransferAddressesResponse {
  whitelist: TransferAddress[];
}
interface GetNetworksResponse {
  networks: BoostNetwork[];
}

export interface TransferAddress {
  id?: number;
  address: string;
  network: string;
  organizationId?: number;
  memo?: string;
  status?: 'Pending' | 'Approved' | 'Denied';
  notes?: string;
  usedWithdrawRequest?: boolean;
  usedWithdrawCompleted?: boolean;
  usedWithdrawError?: boolean;
  verified?: boolean;
  asset?: string;
  networkName?: string;
}

export interface BoostNetwork {
  network: string;
  withdrawalFee?: string;
  withdrawalMin?: string;
  withdrawalMax?: number;
  withdrawNotes?: string[];
  name: string;
  addressRegex?: string;
  networkStatus?: string;
  withdrawalIntegerMultiple?: string;
  requireMemo?: boolean;
  memoRegex?: string;
  isDefault?: boolean;
  exchangeName?: string;
}

interface GetDepositsForWithdrawInterface {
  orders: WithdrawableOrder[];
}
export interface WithdrawableOrder {
  id: number;
  currency: string;
  balance: string;
  id1Token: number;
}

export interface TransactionDetailsResponse {
  transaction: TransactionDetails;
}

export interface WithdrawRequestDetails {
  id: number;
  currency: string;
  amount: number;
  whitelistAddress: string;
  orderId: number;
  transactionId?: number;
  transactionIdRollback?: number;
  organizationId: number;
  name: string;
  emailApproved: boolean;
  otpApproved: boolean;
  requiresExternalApproval: boolean;
  externalApproved: boolean;
  status: string;
  networkTransactionId?: string;
  processingDate?: string;
  requestDate?: string;
  withdrawalFee?: string;
}
export interface TransactionDetails {
  id: number;
  orderId: 2206294646;
  transactionType: string;
  date: string;
  currency: string;
  operationId: number;
  initialAmount: string;
  transactionAmount: string;
  withdrawRequest?: WithdrawRequestDetails;
}
export const {
  useGetDepositsForWithdrawQuery,
  useGetNetworksQuery,
  useGetWhitelistAddressesQuery,
  useCreateAddressMutation,
  useDeleteAddressMutation,
  useCreateWithdrawRequestMutation,
  useWithdrawRequestEmailValidationMutation,
  useWithdrawRequestOtpValidationMutation,
  useWithdrawRequestPasswordValidationMutation,
  useGetOngoingWithdrawRequestsQuery,
  useSetAddressAsVerifiedMutation,
  useGetCompletedTransactionsQuery,
  useGetNetworksApprovedForAssetQuery,
  useGetTransactionDetailsQuery,
  useGetDailyLimitQuery,
  useIsFeatureEnabledQuery,
  useSetCheckTermsServiceMutation,
  useGetTermsServiceCheckedQuery,
  useLazyGetTermsServiceCheckedQuery,
  useSendEmailVerificationMutation
} = withdrawApi;
