import Button from 'components/atoms/Button';
import { TypesNamesEnum } from 'enums/Button.enum';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import RadioButton from 'components/atoms/RadioButton/RadioButton';
import { accreditedIntervals } from './register.constants';
import Turnstile from 'react-turnstile';
import { toast, ToastType } from 'components/organisms/Toast';

interface Props {
  next: () => void;
  back: () => void;
  loading: boolean;
  accreditedValue: [number, number];
  setAccreditedValue: (accreditedValue: [number, number]) => void;
  setTurnstileToken: (token: string) => void;
  turnstileToken: string;
}

const TURNSTILE_ENABLED = process.env.TURNSTILE_ENABLED !== 'false';

const RegisterAccreditedValue: FC<Props> = ({ next, accreditedValue, setAccreditedValue, back, loading, turnstileToken, setTurnstileToken }) => {
  const handleTurnstileVerify = (token: string) => {
    setTurnstileToken(token);
  };

  const handleTurnstileError = () => {
    setTurnstileToken('');
    toast.show({
      type: ToastType.Fail,
      title: "Verification failed",
      content: 'Please try again',
    });
  };

  const getLabel = ([first, _]: [number, number]) => {
    if(first === 0) return '<$100K'
    if(first === 100000) return '$100K to $1M'
    if(first === 1000000) return '>$1M'
    return ''
  }

  const items = accreditedIntervals.map((value, index) => ({
    id: index.toString(),
    label: getLabel(value as [number, number]),
    value: value as [number, number]
  }))

  useEffect(() => {
    handleTurnstileVerify('')
  }, [])

  const TURNSTILE_SITE_KEY = process.env.TURNSTILE_SITE_KEY || '';
  return (
    <>    
      <AccreditedValueBox>
        <p>What is the value of assets you are planning to invest with us?</p>
        <RadioGroup>
          {items.map(item => {
            return (<RadioButton
              key={'accreditedValue-' + item.id}
              size={30}
              name='investmentRange'
              text={getLabel(item.value)}
              value={item.value[0].toString()}
              checked={accreditedValue[0] === item.value[0]}
              onChange={() => setAccreditedValue(item.value)}
            />);
          })}
        </RadioGroup>
        <Grid>
          <Button buttonType={TypesNamesEnum.SECONDAY} disabled={loading} label='Back' onClick={back} type='button' />
          <Button buttonType={TypesNamesEnum.ACCENT} disabled={loading || (TURNSTILE_ENABLED && !turnstileToken)} isLoading={loading} label='Next' onClick={next} type='submit' />
        </Grid>
      </AccreditedValueBox>
      {TURNSTILE_ENABLED && (
        <CaptchaBox>
          <Turnstile
            sitekey={TURNSTILE_SITE_KEY}
            onVerify={handleTurnstileVerify}
            onError={handleTurnstileError}
            onExpire={() => setTurnstileToken('')}
            theme="auto"
            refreshExpired="auto"
            appearance="interaction-only"
          />
        </CaptchaBox>
      )}
    </>
  );
};

const CaptchaBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const AccreditedValueBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Grid = styled.div`
  display: grid;
  gap: 10px;
  height: 50px;
  margin-top: 50px;
  grid-template-columns: 1fr 1fr;
  Button {
    font-weight: 400;
    font-size: 1.1rem;
  }
`;

const RadioGroup = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 2vh;
  margin-top: 0.5vh;
`;

export default RegisterAccreditedValue;
