import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import FormInputBox from 'components/atoms/FormInputBox';
import { palette } from 'lib/theme';
import Button from 'components/atoms/Button';
import { SizeNamesEnum, TypesNamesEnum } from 'enums/Button.enum';
import Modal from 'components/organisms/Modal';
import Row from 'components/atoms/Row/Row';
import { SettingsUser } from 'state/store/api';

interface ChangeRoleModalProps {
    showModal: boolean;
    user: SettingsUser;
    setShowModal: (e: boolean) => void
    onChangeRole: (input: any) => void
}

enum Roles {
    ADMIN = "ADMIN",
    APPROVER = "APPROVER",
    INITIATOR = "INITIATOR",
    VIEWER = "VIEWER",
    ABRA_VIEWER = "ABRA_VIEWER"
}

export const ChangeRoleModal = ({user, onChangeRole, setShowModal, showModal}: ChangeRoleModalProps) => {

    const [role, setRole] = useState<Roles | null>(null)
    useEffect(() => {
        if(!role && user) {
            setRole(user.role as Roles)
        }
    }, [])

    return (
        <Modal visible={showModal} onClose={() => setShowModal(false)}>
            {(
                <Row style={{
                    justifyContent: 'center'
                }}>
                    Change role for {user?.email}
                </Row>
            )}
            <Container>
                <InputWrapper>
                        <StyledSelect
                            value={role || user?.role}
                            onChange={
                                (e: any) => setRole(e.target.value as Roles)
                            }
                            
                        >
                            {Object.keys(Roles).map(role => <option value={role}>{role}</option>)}
                        </StyledSelect>
                </InputWrapper>
            </Container>
            <Row style={{
                padding: 10,
                justifyContent: 'space-between'
            }}>
                <Button 
                    buttonType={TypesNamesEnum.SECONDAY} 
                    size={SizeNamesEnum.DEFAULT}
                    onClick={() => setShowModal(false)}
                >
                    Back
                </Button>
                <Button 
                    disabled={!role}
                    buttonType={TypesNamesEnum.ACCENT} 
                    size={SizeNamesEnum.DEFAULT}
                    onClick={() => {
                            onChangeRole({role: role, id: user?.userId}); 
                            setShowModal(false)
                        }
                    }
                >
                    Confirm
                </Button>
            </Row>
        </Modal>
    )
}

const InputWrapper = styled.div`
  margin-right: 12px;
  border: 1px solid ${palette.white.main};
  padding: 8px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: 90%;
  background-color: ${palette.darkBackgroundContrast.light10};
`

const StyledSelect = styled.select`
  width: 100%;
  background-color: transparent;
  color: white;
  border: unset;
  option {
    background-color: ${palette.accent.main};
  }
`

const Container = styled.div`
    padding: 15px;
    display: flex;
    justify-content: center;
`