import React from 'react';
import styled from 'styled-components';
import { MarginContainer } from '../Custody';
import CustodyNavigationWidget from '../CustodyNavigationWidget';
import Layout from 'components/templates/Layout';
import useCustodyRedirect from '../extraCustodyHooks/useCustodyRedirect';
import Widget from 'components/templates/Widget';
import useCustody from '../useCustody';
import {
    Step,
    BankInfoTable,
    InfoField,
    BankInfoData,
    copyText,
    CopyText,
} from './BankWireSteps';
import PrintIcon from 'assets/icons/PrintIcon';
import { Copy } from 'assets/icons';
import { openInNewTab } from 'shared/openInNewTab';
import { wireInformationURL } from 'lib/constants/urls';
import Link from 'components/atoms/Link';

const FiatMainPage: React.FC = () => {
    const { smaAccountNumber, user } = useCustody();

    useCustodyRedirect();

    const bankInfo: BankInfoData = {
        recipientName: 'BitGo Trust Company, Inc',
        routingNumber: '031302971',
        bankName: 'Customers Bank',
        accountNumber: '5766400',
        bankAddress: '40 General Blvd Suite 200 Malvern PA 19355',
        recipientAddress: '6216 S Pinnacle Pl Ste #101 Sioux Falls, SD 57108',
    };

    const handlePrint = () => {
        window.print();
    };

    const emailText = `${
        user?.firstName + ' ' + user?.lastName
    }, ${smaAccountNumber}, Amount Wired, Asset You Would Like to Purchase`;

    const memoText = 'FBO Abra Capital Management - SIJYSLYOAV';

    return (
        <Layout>
          <PrintableDix>

            <CustodyNavigationWidget>
              <Header>
                <div>
                    <Title>Bank Wire with Abra</Title>
                    <div>We are only able to process greater than $100K wires. Please do not send smaller wires as they will be returned</div>
                </div>
                <PrintButton onClick={handlePrint}>
                    <PrintIcon />
                </PrintButton>
              </Header>
                <Widget>
                    <Step number={1}>Enter Bank Wire Information</Step>
                    <BankInfoTable data={bankInfo} />
                </Widget>
                <Widget>
                    <Step number={2}>
                        Make sure that your memo/reference field (message to
                        recipient's bank) only includes the following:
                    </Step>
                    <InfoField>
                        <CopyText onClick={() => copyText(memoText)}>
                            {memoText}
                            <Copy />
                        </CopyText>
                    </InfoField>
                </Widget>
                <Widget>
                    <Step number={3}>
                        Email{' '}
                        <StyledLink
                            href='mailto:support@abra.com?subject=Ref: Bank wire with Abra Prime'
                            aria-label='Email support'
                            >
                            support@abra.com
                        </StyledLink>{' '}
                        with the following:
                    </Step>
                    <InfoField>
                        <CopyText onClick={() => copyText(emailText)}>
                            {emailText}
                            <Copy />
                        </CopyText>
                    </InfoField>
                    <StyledRedirectLink>If you have any questions, please read
                    <Link
                        label={' How do I wire funds into my Abra account?'}
                        onClick={() => {
                            openInNewTab(wireInformationURL);
                        }}
                    />
                    </StyledRedirectLink>
                </Widget>
            </CustodyNavigationWidget>
          </PrintableDix>
        </Layout>
    );
};

const StyledRedirectLink = styled.div`
    padding-top: 15px;
`

const PrintableDix = styled.div`
   @media print {
    a, p, span, div {
      color: black !important;
    }
   }
`;

const Header = styled.div`
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const PrintButton = styled.div`
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #6f2acd99;
    padding: 6px;
    border-radius: 50%;
    transition: all 1s;
    &:hover {
        background-color: #6f2acd;
    }
`;

const Container = styled.div`
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
    overflow-x: hidden;
`;

const Title = styled.div`
    font-weight: 600;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    font-size: 35px;
`;

const StyledLink = styled.a`
    color: #9370db;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

export default FiatMainPage;
