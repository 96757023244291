import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { logout } from "state/slice/auth.slice";
import { RootState } from "state/store";

const unauthorizedAllowedEndpoints = [
  "/withdraw/request",
  "/withdraw/otp-validate",
  "/withdraw/email-validate"
]

const baseQuery = fetchBaseQuery({
  baseUrl: "/api",
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;

    if (token) headers.set("authorization", token);

    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (
    result.error && 
    result.error.status === 401 && 
    !unauthorizedAllowedEndpoints.some(item => item.includes(args?.url))
  ) {
    api.dispatch(logout());
  }
  return result;
};

export const emptySplitApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["ApiKeys"],
  endpoints: () => ({}),
});
