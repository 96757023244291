import React from 'react'
import styled from 'styled-components';
import FormInputBox from 'components/atoms/FormInputBox';
import { palette } from 'lib/theme';
import Button from 'components/atoms/Button';
import { SizeNamesEnum, TypesNamesEnum } from 'enums/Button.enum';
import Modal from 'components/organisms/Modal';
import Row from 'components/atoms/Row/Row';
import Loading from 'components/atoms/Loading';
import { SpinnerSizeEnum } from '@types/enums/Loading.enum';

interface FormModalProps {
    showModal: boolean;
    formItems: Record<string, any>
    handleChangeForm: any
    formValue: Record<string, any>
    setShowModal: (e: boolean) => void
    saveForm: () => void
    modalTitle?: string;
    isLoading?: boolean
    isDisabled?: boolean
}

export const FormModal = ({formItems, formValue, handleChangeForm, setShowModal, saveForm, showModal, modalTitle, isDisabled, isLoading}: FormModalProps) => {
    return (
        <Modal visible={showModal} onClose={() => setShowModal(false)}>
            {modalTitle && (
                <Row style={{
                    justifyContent: 'center'
                }}>
                    {modalTitle}
                </Row>
            )}
            <ReviewTable>
                <tbody>
                    {formItems.map((row) => (
                        <tr key={row.title}>
                            <th>{formatKey(row.title)}</th>
                            <CopyText>
                                <FormInputTable>
                                    <InputAmount 
                                        value={formValue[row.fieldName]}
                                        placeholder={row.title}
                                        onChange={(e) => handleChangeForm(e, row.fieldName)}
                                    />
                                </FormInputTable>
                            </CopyText>
                        </tr>
                    ))}
                </tbody>
            </ReviewTable>
            <Row style={{
                padding: 10,
                justifyContent: 'space-between'
            }}>
                <Button 
                    buttonType={TypesNamesEnum.SECONDAY} 
                    size={SizeNamesEnum.DEFAULT}
                    onClick={() => setShowModal(false)}
                    disabled={isLoading || isDisabled}
                >
                    Back
                </Button>
                <Button 
                    buttonType={TypesNamesEnum.ACCENT} 
                    size={SizeNamesEnum.DEFAULT}
                    onClick={() => saveForm()}
                    disabled={isLoading}
                >
                    {isLoading && 
                        <Loading 
                            showText={false}  
                            size={SpinnerSizeEnum.BUTTON}
                        />
                    }
                    {!isLoading && "Confirm"}
                    
                </Button>
            </Row>
        </Modal>
    )
}

const formatKey = (key: string): string => {
    return key
        .split(/(?=[A-Z])/)
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

const CopyText = styled.td`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1vw;
    cursor: pointer;
`;

const PrintableDix = styled.div`
   @media print {
    a, p, span, div {
      color: black !important;
    }
   }
`;

const Header = styled.div`
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const InputAmount = styled.input`
    text-align: center;
    border: 1px solid white;
    border-radius: 4px;
    background-color: transparent;
    color: ${palette.white.main};
    min-width: 32ch;
    width: 100%;
    padding: 10px 0px;
    flex-grow: 1;
    max-width: 100%;
    display: flex;
    -moz-appearance: textfield;
    appearance: textfield;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &:focus {
        outline: none;
    }
`;  

const StyledLi = styled.li`
    padding: 10px;
`;

const PrintButton = styled.div`
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #6f2acd99;
    padding: 6px;
    border-radius: 50%;
    transition: all 1s;
    &:hover {
        background-color: #6f2acd;
    }
`;

const Container = styled.div`
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
    overflow-x: hidden;
`;

const Title = styled.div`
    font-weight: 600;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    font-size: 35px;
`;

const Table = styled.table`
    width: calc(100% - 3rem);
    border-collapse: collapse;
    margin-top: 10px;
    margin-left: 3rem;
    border: none;

    th,
    td {
        padding: 8px;
        text-align: left;
        vertical-align: middle;
    }

    th {
        width: 30%;
        font-weight: bold;
    }
`;

const ReviewTable = styled(Table)`
    margin-left: 1.3rem;
    border-collapse: collapse;
    border: none;

    th,
    td {
        padding: 8px;
        text-align: left;
        vertical-align: middle;
    }

    th {
        width: 30%;
        font-weight: bold;
    }
`;

const FormInputTable = styled(FormInputBox)`
    width: 100%;
`;

const FormInputBoxStyled = styled(FormInputBox)`
    flex-direction: column;
    align-items: baseline;
`