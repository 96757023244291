import React from 'react';
import { ThemeProvider } from 'styled-components';
import Layout from 'components/templates/Layout';
import useCustodyRedirect from '../extraCustodyHooks/useCustodyRedirect';
import { BorrowPortfolio } from 'pages/DefiBorrow/BorrowPortfolio';

const theme = {
    colors: {
        primary: '#4f1a8d',
        secondary: '#3e146f80',
        text: '#ffffff'
    }
};

const LoanMainPage: React.FC = () => {
    useCustodyRedirect();

    return (
        <ThemeProvider theme={theme}>
            <Layout>
                  <BorrowPortfolio />
            </Layout>
        </ThemeProvider>
    );
};

export default LoanMainPage;
