import { ReactNode } from "react";
import styled from "styled-components";

export interface RowProps {
  direction?: "row" | "column";
  justifyContent?: string;
  alignItems?: string;
  margin?: string;
  marginTop?: string;
  marginLeft?: string;
  gap?: string;
  width?: string;
  height?: string;
  wrap?: string;
  flex?: string;
  hasBorder?: boolean;
  children?: ReactNode;
  className?: string;
}

const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction || "row"};
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems}` : "")};
  ${({ wrap }) => (wrap ? `flex-wrap: ${wrap}` : "")};
  ${({ flex }) => (flex ? `flex: ${flex}` : "")};
  ${({ justifyContent }) =>
    justifyContent ? `justify-content: ${justifyContent}` : ""};
  ${({ marginTop }) => (marginTop ? `margin-top: ${marginTop}` : "")};
  ${({ marginLeft }) => (marginLeft ? `margin-left: ${marginLeft}` : "")};
  ${({ margin }) => (margin ? `margin: ${margin}` : "")};
  ${({ gap }) => (gap ? `gap: ${gap}` : "")};
  ${({ width }) => (width ? `width: ${width}` : "")};
  ${({ height }) => (height ? `height: ${height}` : "")};
  ${({ hasBorder }) =>
    hasBorder && `border: 1px solid rgba(255, 255, 255, 0.1); border-radius: 8px`};
`;

export default Row;
