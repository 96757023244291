import React, { useEffect } from "react";
import LtvNotification from "./LtvNotification";
import { useLazyGetCustodyLoanSummaryQuery } from "state/store/loanApi";


const CustodyPortfolioLtvNotification: React.FC = () => {

    const [getLoanSummary, loanSummary] = useLazyGetCustodyLoanSummaryQuery();

    useEffect(() => {
        getLoanSummary();
    }, [])

    return (<>
        <LtvNotification ltvStatus={loanSummary?.data?.ltvStatus} loanStatus={loanSummary?.data?.status} />
    </>);
};

export default CustodyPortfolioLtvNotification;