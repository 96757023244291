import { SpinnerSizeEnum } from "@types/enums/Loading.enum";
import Loading from "components/atoms/Loading";
import { palette } from "lib/theme";
import React, { useState, useRef, useEffect } from "react";
import styled from 'styled-components'

export interface ActionProps {
  id: string,
  role?: string,
  email?: string
}

export interface DropdownOptions {
    action: (obj: ActionProps) => void
    label: string
}

interface DropdownButtonProps {
  items: DropdownOptions[]
  approver: any
  isDisabled: boolean
  setCurrentUser: (input: any) => void
}

const DropdownButton = ({items, approver, isDisabled, setCurrentUser}: DropdownButtonProps) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer 
      ref={dropdownRef}
      onClick={() => {
          if(!isDisabled) {
            setOpen(prevState => !prevState)
          }
        }
      }
    >
      <Dropdown>
        {!open && isDisabled && 
          <Loading 
            showText={false}  
            size={SpinnerSizeEnum.BUTTON}
          />
        }
        {
          !isDisabled && (
            <Button>
              Action
              {open && (
                <DropdownContent>
                  {items.map((item) => (
                    <DropdownItem
                      key={item.label}
                      onClick={() => {
                        setCurrentUser(item);
                        item.action({ id: approver.userId, email: approver.email });
                      }}
                    >
                      {item.label}
                    </DropdownItem>
                  ))}
                </DropdownContent>
              )}
            </Button>
          )
        }
      </Dropdown>
    </DropdownContainer >
  );
};

export default DropdownButton;

const DropdownContainer  = styled.div`
  width: 10%;
  border: 1px solid ${palette.white.main};
  display: flex; 
  justify-content: 'center';
  border-radius: 4px;
`;

const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  align-content: center;
  width: 100%;
  text-align: center;
`;

const DropdownContent = styled.div`
  position: absolute;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border: 1px solid ${palette.white.main};
  border-radius: 4px;
  margin-top: 7px;
  background: ${palette.accent.dark};
  width: inherit;
`;

const DropdownItem = styled.span`
  color: ${palette.white.main};
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  &:hover {
    background-color: ${palette.accent.light80};
  }
`;

const Button = styled.div`
  height: auto;
  align-content: center;
`;
