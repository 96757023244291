import React, { useEffect, useState } from 'react';
import { RateOfReturn, useLazyGetRateOfReturnsQuery, useLazyGetRateOfYieldQuery } from 'state/store/custodyApi';
import { Chart } from 'react-google-charts';
import Modal from 'components/organisms/Modal';
import { DefiBalanceRow } from 'state/store/investApi';
import { Percentage } from 'lib/utils/types';
import Tooltip from 'components/atoms/Tooltip/Tooltip';
import QuestionCircle from 'assets/icons/QuestionCircle';
import moment from 'moment';
import styled from 'styled-components';

interface Props {
  defiBalanceRow: DefiBalanceRow;
  type: 'Yield' | 'HistoricalYield' | 'Return';
}

const CustodyRateOfReturns: React.FC<Props> = ({ defiBalanceRow, type }) => {
  const [getReturns, rateOfReturns] = useLazyGetRateOfReturnsQuery();
  const [getYield, rateOfYield] = useLazyGetRateOfYieldQuery();

  useEffect(() => {
    if (type === 'Return') {
      getReturns(defiBalanceRow.identifier);
    }

    if (type === 'Yield' || type === 'HistoricalYield') {
      getYield(defiBalanceRow.identifier);
    }
  }, [defiBalanceRow.identifier, getReturns, getYield, type]);

  const [visible, setVisible] = useState(false);
  const rates = type === 'Return' ? [...(rateOfReturns?.data ?? [])] : [...(rateOfYield?.data ?? [])];

  const getMonthName = (yearMonth: string) => {
    const month = yearMonth.split('-')[1];
    const date = new Date(2024, +month - 1);
    return date.toLocaleString('default', { month: 'short' });
  };

  const format = (rateOfReturn: RateOfReturn) => {
    const month = getMonthName(rateOfReturn.month);
    const rate = +rateOfReturn.rate * 100;
    return {
      month,
      rate,
    };
  };

  const formatMonth = (month: string | undefined): string => {
    if (!!month) {
      return moment.utc(month).format('MMM YYYY');
    }
    return '';
  };

  const getRateText = (firstMonth: string | undefined, lastMonth: string | undefined): string => {
    if (type === 'Return') {
      const prefix = 'Rate of Return is your actual return ';
      const suffix = ' after taking into account both income and fees.';
      if (firstMonth === undefined || lastMonth === undefined) {
        return prefix + 'during your investment period,' + suffix;
      }
      if (firstMonth === lastMonth) {
        return prefix + 'of ' + formatMonth(firstMonth) + suffix;
      }
      return prefix + 'between ' + formatMonth(firstMonth) + ' and ' + formatMonth(lastMonth) + suffix;
    }

    if(type === 'HistoricalYield') {
      let prefix = 'Yield is calculated as an annualized APR based on the ';
      switch (defiBalanceRow.yield.method) {
        case 'rolling7':
          prefix += 'last 7 days of actual interest';
          break;
        case 'rolling30':
          prefix += 'last 30 days of actual interest';
          break;
        case 'rolling7income':
          prefix += 'actual interest paid out over last 7 days';
          break;
        case 'rolling30income':
          prefix += 'actual interest paid out over last 30 days';
          break;
        case 'rolling30price':
          prefix += 'price change over the last 30 days';
          break;
        case 'rolling12months':
          prefix += 'actual interest paid out over last 12 months or max period available';
          break;
        case 'rolling52weeks':
          prefix += 'actual interest paid out over last 52 weeks or max period available';
          break;
        case 'rolling365days':
          prefix += 'actual interest paid out over last 365 days or max period available';
          break;
        case 'lastday':
          prefix += 'actual interest for last day.';
          break;
        case 'lastweek':
          prefix += 'actual interest paid last week';
          break;
        case 'lastmonth':
          prefix += 'actual interest paid out last month';
          break;
        case 'lastfourweeks':
          prefix += 'actual interest paid out in the last 4 weeks';
          break;
        default:
          '';
          break;
      }

      return prefix
    }

    return 'Expected yield at this time';
  };

  const dataToDisplay = () => {
    let rateOfReturn = '';
    let header = '';

    if (type === 'Return') {
      rateOfReturn = new Percentage(defiBalanceRow?.return?.rate).print();
      header = 'Rate of Return';
    } else if (type === 'HistoricalYield') {
      header = 'Historical Yield';
      rateOfReturn = new Percentage(defiBalanceRow.yield?.apr).print();
    } else {
      header = 'Yield';
      rateOfReturn = new Percentage(defiBalanceRow.expectedAPR).print();
    }

    return {
      rateOfReturn,
      header,
    };
  };

  const { rateOfReturn, header } = dataToDisplay();
  const firstMonth = defiBalanceRow?.return?.firstMonth;
  const lastMonth = defiBalanceRow?.return?.lastMonth;
  rates?.sort((a, b) => a.month.localeCompare(b.month));
  const result = rates.map(format).map((r) => [r.month, r.rate, `${r.rate.toFixed(2)}%`]);

  return (
    <ParentContainer onClick={() => { result.length > 0 && setVisible(!visible); }}>
      {rateOfReturn != null && (
        <>
          <Tooltip text={getRateText(firstMonth, lastMonth)} placement="top">
            <HoverArea>
              <Button disabled={result.length <= 0}>{rateOfReturn || defiBalanceRow.expectedAPR }</Button>
              <QuestionCircle color='#fffc' />
            </HoverArea>
          </Tooltip>
        </>
      )}
      {rateOfReturn == null && <>-</>}
      <Modal visible={visible} onClose={() => setVisible(false)} header={header}>
        <Modal.Body>
          <Container hidden={!visible}>
            <Chart
              className="chart"
              chartType="LineChart"
              data={[
                [{ type: 'string', label: 'Month' }, { type: 'number', label: 'Rate' }, { type: 'string', role: 'tooltip', p: { html: true } }],
                ...result,
              ]}
              width="100%"
              height="400px"
              options={{
                legend: 'none',
                colors: ['#6F2ACD'],
                backgroundColor: 'transparent',
                hAxis: {
                  title: 'Month',
                  textStyle: {
                    color: 'white',
                  },
                  titleTextStyle: {
                    color: 'white',
                  },
                },
                vAxis: {
                  title: 'Rate (%)',
                  textStyle: {
                    color: 'white',
                  },
                  titleTextStyle: {
                    color: 'white',
                  },
                },
              }}
            />
          </Container>
        </Modal.Body>
      </Modal>
    </ParentContainer>
  );
};

interface ButtonProps {
  disabled?: boolean
}
const Button = styled.button<ButtonProps>`
  background-color: #6f2acdcc;
  border-radius: 4px;
  color: white;
  font-size: 0.7rem;
  margin-left: 2px;
  border: transparent;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  transition: all 0.3s ease-in;
  padding: 2px;
  &:hover {
    background-color: rgba(163, 153, 246, 0.8);
  }
`

const ParentContainer = styled.div`
`
const Container = styled.div`
  padding: 8px;
  width: 500px;
  height: 500px;
  border-radius: 8px;
  position: relative;
`

const HoverArea = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

export default CustodyRateOfReturns;