import React, {FC} from 'react';
import PanelWithHeader from 'components/molecules/PanelWithHeader';
import styled from 'styled-components';
import {palette} from 'lib/theme';
import {AssetDto, useGetAssetsQuery} from 'state/store/api';
import Loading from 'components/atoms/Loading';
import { CustodyMainText, CustodySubText } from 'components/pages/custody/CustodyStyles';
import Image from 'components/atoms/Image';
import { CustodyBalanceDetail } from 'state/store/custodyApi';
import useCustody from 'components/pages/custody/useCustody';
import TokenQuantity from 'components/atoms/TokenQuantity/TokenQuantity';
import { isNegative } from 'utils/isNegative';
import getUsDollar from 'shared/dollarFormat';
import bigDecimal from 'js-big-decimal';
import { SelectedAsset } from 'interfaces/SelectedAsset.interface';
import Tooltip from 'components/atoms/Tooltip';
import { Percentage } from 'lib/utils/types';
import { useNavigate } from 'react-router-dom';
import { allowedWithdraw } from 'utils/allowedWithdraw';
import usePermissions from 'shared/usePermissions';

interface DefiBorrowPortfolioProps {
  title: string
  sectionHelperText?: string
  mainOperationButtonTitle?: string
  availableAssets: CustodyBalanceDetail[]
  openModal: (value: boolean) => void
  selectAsset: (value: SelectedAsset) => void
  handleOperation: any
  isPrincipal: any
  width?: string
  step?: number
  hasBorder?: boolean
  placeholderText?: string
  disabledTooltip?: string
  placeholderCondition?: boolean
  mainOperationButtonDisabled?: boolean
  transactionInProgress?: boolean
  allowWithdraw?: boolean
}

const DefiBorrowPortfolio: FC<DefiBorrowPortfolioProps> = (props: DefiBorrowPortfolioProps) => {
  const {getAssetByIdentifier} = useCustody();
  const navigate = useNavigate();

  const {isLoading: assetsLoading} = useGetAssetsQuery();

  const { canWithdrawFiatCustody } = usePermissions();

  const handleMainOperation = (details: CustodyBalanceDetail, asset?: AssetDto) => {
    props.isPrincipal()
    props.handleOperation()
    props.selectAsset({
      asset,
      details
    });
    props.openModal(true);
  }

  const isSectionLoading = assetsLoading

  return (
    <PanelWithHeader 
      width={props.width || '100%'} 
      minHeight='174px'
      innerHeight='unset'
      title={props.title}
      hasBorder={props.hasBorder}
      description={props.sectionHelperText}
    >
      {isSectionLoading && (
        <div>
          <Loading showText={false}/>
        </div>
      )}
      {!isSectionLoading && props.placeholderCondition && 
        <div style={{
          display: `flex`,
          justifyContent: `center`,
          height: `100%`,
          alignItems: `center`
        }}>
          {props.placeholderText}
        </div>
      }
      {!isSectionLoading && !props.placeholderCondition && (
        <div id={props.placeholderText}>
        <LoanStyledTable>
          <thead>
            <tr>
              <th>Asset</th>
              <th>Balance</th>
              <th>APY</th>
            </tr>
          </thead>
          <tbody>
            {props.availableAssets?.map((asset) => {
              const assetDetails = getAssetByIdentifier(asset.balance.asset)
              const hasBalance = new bigDecimal(asset?.balance?.amount).compareTo(new bigDecimal(0)) > 0;
              return (
                <tr key={asset.balance?.asset + '-' + asset.network}>
                  <td>
                    <TokenImageBox>
                      {asset && (
                        <>
                          <ImageBox>
                            <Image width='100%' height='100%' src={assetDetails?.icon} />
                          </ImageBox>
                          <TextGroup>
                            <CustodyMainText>{assetDetails?.name}</CustodyMainText>
                            <CustodySubText>{asset.networkDescription}</CustodySubText>
                          </TextGroup>
                        </>
                      )}
                    </TokenImageBox>
                  </td>
                  <td>
                    <TextGroup>
                      <CustodyMainText isNegative={isNegative(asset.balance?.amount)}>
                        <TokenQuantity asset={asset.balance?.asset} quantity={asset.balance?.amount} />
                      </CustodyMainText>
                      {asset.balanceValue && <CustodySubText isNegative={isNegative(asset.balanceValue?.amount)}>{getUsDollar(asset.balanceValue?.amount)} USD</CustodySubText>}
                    </TextGroup>
                  </td>
                  <td style={{width: '10%'}}>
                    <TextGroup>
                      {new Percentage(asset?.apy).print()}
                    </TextGroup>
                  </td>
                  {props.allowWithdraw && 
                  (<td>
                    <CenterContent>
                      {props.mainOperationButtonTitle && 
                        <Tooltip
                          autoDismiss={true}
                          text={props.transactionInProgress ? "Transaction in progress, please wait" : props.disabledTooltip}
                          visible={!hasBalance || props.transactionInProgress}
                        >
                          <ButtonWrapper>
                            {
                              canWithdrawFiatCustody &&
                              assetDetails?.name && 
                              allowedWithdraw.includes(assetDetails?.name) && (
                                <ButtonStyled 
                                  onClick={() => navigate(`/custody/withdraw-fiat?asset=${assetDetails?.identifier}&network=${asset?.network}`)} 
                                  disabled={!hasBalance || props.transactionInProgress}
                                >
                                  Withdraw USD
                                </ButtonStyled>)
                            }
                          </ButtonWrapper>
                        </Tooltip>
                      }
                    </CenterContent>
                  </td>)
                  }
                  <td>
                    <CenterContent>
                      {props.mainOperationButtonTitle && 
                        <Tooltip
                          autoDismiss={true}
                          text={props.transactionInProgress ? "Transaction in progress, please wait" : props.disabledTooltip}
                          visible={!hasBalance || props.transactionInProgress}
                        >
                          <ButtonWrapper>
                            <ButtonStyled 
                              onClick={() => handleMainOperation(asset, assetDetails)} 
                              disabled={!hasBalance || props.transactionInProgress}
                            >
                              {props.mainOperationButtonTitle}
                            </ButtonStyled>
                          </ButtonWrapper>
                        </Tooltip>
                      }
                    </CenterContent>
                  </td>

                </tr>
              )
            })}
          </tbody>
        </LoanStyledTable>
        </div>
      )}
    </PanelWithHeader>
  );
};

const ButtonWrapper = styled.div`
  display: flex ;
  flex-direction: row;
  width:100%;
  justify-content: center;
`

const ButtonStyled = styled.button`
  cursor: pointer;
  text-align: center;
  background-color: ${({ disabled }) => (disabled ? `${palette.gray.second}` : `${palette.accent.main}`)};
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: ${palette.white.main};
  font-size: 0.8rem;
  opacity: 0.9;
  border: 1px solid transparent;
  transition: all 0.3s ease-in;
  width: 75%;
  min-height: 37px;
  justify-content: center;
  &:hover {
    background-color: ${({ disabled }) => (disabled ? `${palette.gray.second}` : `${palette.accent.light80}`)};
  }
`;

const CenterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`

const TokenImageBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6vw;
`;

const ImageBox = styled.div`
  max-width: calc(min(4vw, 32px));
  min-width: calc(min(4vw, 32px));
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LoanStyledTable = styled.table`
  margin-top: 2vh;
  width: 100%;
  border-collapse: separate;
  table-layout: fixed;
  @media print {
      font-weight: 600;
    }
  tbody tr {
    background-color: rgba(0,0,0,0.1);
    margin-top: 2px;
    padding: 0;
    @media print {
      background-color: white;
    }
    &:hover {
      @media print {
      background-color: white;
    }
      border-radius:8px;
      overflow: hidden;
    }
  }
  td {
    padding: 5px;
    font-size: calc(min(1.5vw, 14px));
    @media print {
      color: black;
      font-weight: 600;
    }
    :last-child {
      padding: unset;
    }
  }
  th {
    font-weight: 400;
    color: rgba(255,255,255,0.7);
    font-size: 12px;
    text-align: left;
    padding-right: 30px;
    @media print {
      color: black;
      font-weight: 800;
    }
  }
  
`


export default DefiBorrowPortfolio;
