import React from "react";
import { useParams } from "react-router-dom";
import { useGetAnnualExpensesDetailsQuery, useGetAnnualExpensesSummaryQuery, useGetExpensesReportCSVMutation } from "state/store/reportApi";
import DownloadCsvButton from "../DownloadCsvButton";
import alternativeGridSystem from "lib/theme/alternativeGridSystem";
import Layout from "components/templates/Layout";
import { MarginContainer } from "../../Custody";
import CustodyNavigationWidget from "../../CustodyNavigationWidget";
import CustodyExpensesSummary from "./CustodyExpensesSummary";
import CustodyExpensesTransactionDetails from "./CustodyExpensesTransactionDetails";
import DocumentTitleRow from "components/molecules/DocumentTitleRow";
import styled from "styled-components";

const CustodyExpenses: React.FC = () => {
    const { year } = useParams();
    const numericYear = year ? parseInt(year, 10) : new Date().getFullYear();

    const { data: transactions, isLoading: isDetailsLoading } =
        useGetAnnualExpensesDetailsQuery(numericYear);
    const { data: summaryData, isLoading: isSummaryLoading } =
        useGetAnnualExpensesSummaryQuery(numericYear);
    const isLoading = isDetailsLoading || isSummaryLoading;
    return (<>
        <Layout customGrid={alternativeGridSystem}>
            <MarginContainer id='pdf-area'>
                <CustodyNavigationWidget>
                    <HeaderContainer>
                        <DocumentRowWrapper>
                            <DocumentTitleRow isLoading={isLoading} title='Expense Report' />
                        </DocumentRowWrapper>
                        <DownloadCsvButton
                            title="Expenses Report"
                            year={numericYear}
                            csvMutation={useGetExpensesReportCSVMutation}
                        />
                    </HeaderContainer>
                    <CustodyExpensesSummary
                        summaryData={summaryData}
                        year={numericYear}
                        />
                    <CustodyExpensesTransactionDetails
                        transactions={transactions}
                    />
                </CustodyNavigationWidget>
            </MarginContainer>
        </Layout>
    </>);
};

const DocumentRowWrapper = styled.div`
    align-self: start;
    margin-right: 10px;
    width: 100%;
`

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
`;

export default CustodyExpenses;