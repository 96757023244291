import Button from 'components/atoms/Button';
import { TypesNamesEnum } from 'enums/Button.enum';
import React, { FC } from 'react';
import styled from 'styled-components';

interface Props {
  back: () => void
}

const RegisterError: FC<Props> = ({back}) => {
  return (
    <RegisterErrorBox>
      <p>Unfortunately, Abra's products require a minimum investment of $100K.</p>
      <p>We are working tirelessly to lower this threshold, and will reach out to you when we have products that match your needs.</p>
      <p>If you would like to update your response, please click below.</p>
      <Grid>
        <Button buttonType={TypesNamesEnum.SECONDAY} label='Back' onClick={back} type='button' />
      </Grid>
    </RegisterErrorBox>
  );
};

const RegisterErrorBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`
const Grid = styled.div`
  display: grid;
  gap: 10px;
  height: 50px;
  grid-template-columns: 1fr 1fr;
  Button {
    font-weight: 400;
    font-size: 1.1rem;
  }
`;

export default RegisterError;
