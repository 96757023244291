import React, { FC, KeyboardEvent, useEffect, useState } from 'react';
import PrimeTerms from 'assets/abra-prime.docx';
import Modal from "components/organisms/Modal";
import styled from 'styled-components';
import Checkbox from 'components/atoms/Checkbox';
import Input from 'components/atoms/Input';
import DigitVerification from 'components/atoms/DigitVerification';
import Button from 'components/atoms/Button';
import { TypesNamesEnum } from 'enums/Button.enum';
import { toast, ToastType } from 'components/organisms/Toast';
import { BankInfo, FiatWithdrawInfoResponse, useGetWithdrawTransactionRequestMutation, useSendEmailVerificationFiatMutation, useWithdrawRequestEmailValidationFiatMutation, useWithdrawRequestOtpValidationMutation, useWithdrawRequestPasswordValidationFiatMutation } from 'state/store/fiatWithdrawApi';
import bigDecimal from 'js-big-decimal';
import usePermissions from 'shared/usePermissions';
import { AccessTokenWrapper, useLazyGetTermsServiceCheckedQuery, useSetCheckTermsServiceMutation } from 'state/store/withdrawApi';
import { useNavigate } from 'react-router-dom';
import useAssets from 'shared/useAssets';

interface WithdrawConfirmModalProps {
    startWithdrawModal: boolean;
    network?: FiatWithdrawInfoResponse;
    bankInfo: BankInfo;
    networkName: string | null;
    asset: string | undefined;
    withdrawAmount: string | number;
    balance: bigDecimal;
    setStartWithdrawModal: (value: boolean) => void
}

const stepsModalHeaderMapper: Record<string, string> = {
    terms:'Terms of Service',
    password:'Step 1 of 3 - Password Validation',
    otp:'Step 2 of 3 - Authenticator Code Validation',
    email:'Step 3 of 3 - Email Code',
    confirm: 'Confirmation'
}

export const WithdrawConfirmModal = ({
    startWithdrawModal, 
    networkName,
    bankInfo, 
    asset,
    withdrawAmount,
    balance,
    setStartWithdrawModal
}:WithdrawConfirmModalProps) => {
    const [password, setPassword] = useState('');
    const [otpCode, setOtpCode] = useState<string[]>([]);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [withdrawStep, setWithdrawStep] = useState<'terms' | 'password' | 'otp' | 'email' | 'confirm'>('terms');
    const [emailCode, setEmailCode] = useState<string[]>([]);
    const [transactionPublicUID, setTransactionPublicUID] = useState<string | null>()
    const [transactionId, setTransactionId] = useState<number | null>()
    const [validationToken, setValidationToken] = useState<AccessTokenWrapper>();
    const [loading, setLoading] = useState(false);
    const { getPriceFormattedI } = useAssets();

    const { username } = usePermissions();
    const [getTerms, terms] = useLazyGetTermsServiceCheckedQuery()
    const [checkTerms] = useSetCheckTermsServiceMutation()

    const [passwordValidation] = useWithdrawRequestPasswordValidationFiatMutation();
    const [sendEmailValidationCode] = useSendEmailVerificationFiatMutation();
    
    const [otpValidation] = useWithdrawRequestOtpValidationMutation();
    const [createWithdrawRequest] = useGetWithdrawTransactionRequestMutation();
    const [emailValidation] = useWithdrawRequestEmailValidationFiatMutation();

    const navigate = useNavigate()

    useEffect(() => {
      getTerms()
    }, [])

    useEffect(() => {
      setTermsAccepted(terms.data?.checked || false)
      if(terms.data?.checked)
        setWithdrawStep('password')
    }, [terms])

    const clickTermsAccepted = async () => {
        setTermsAccepted(!termsAccepted)
        await checkTerms(!termsAccepted)
        getTerms()
    }

    const setFirstStep = () => {
        if(termsAccepted) setWithdrawStep('password')
        else setWithdrawStep('terms')
    }

    const cancelWithdraw = () => {
        setStartWithdrawModal(false);
        setFirstStep()
        setPassword('');
        setOtpCode([]);
        setEmailCode([]);
    };

    const currentStepIsValid = () => {
        if (withdrawStep === 'terms') {
          return termsAccepted;
        }
        if (withdrawStep === 'password') {
          return password;
        }
        if (withdrawStep === 'otp') {
          return otpCode.length === 6;
        }
        if (withdrawStep === 'email') {
          return emailCode.length === 8;
        }
        return true;
    };
    
    const keyDownOtp = (event: { keyCode: number }, indx: number) => {
        if (event.keyCode === 8) {
            if (otpCode[indx]) {
                const updatedCureentValue = otpCode.slice(0, indx);
                setOtpCode(updatedCureentValue);
                return;
            }
            const updatedValue = otpCode.slice(0, indx - 1);
            setOtpCode(updatedValue);
        }
    };

    const changeOtp = (event: any) => {
        setOtpCode([...otpCode, event?.target?.value]);
    };

    const pasteEmailCode = async () => {
        const text = await navigator.clipboard.readText();
        if (text.trim().length === 8) {
          setEmailCode(text.trim().split(''));
        }
    };

    const keyDownEmail = (event: { keyCode: number }, indx: number) => {
        if (event.keyCode === 8) {
          if (emailCode[indx]) {
            const updatedCureentValue = emailCode.slice(0, indx);
            setEmailCode(updatedCureentValue);
            return;
          }
          const updatedValue = emailCode.slice(0, indx - 1);
          setOtpCode(updatedValue);
        }
    };
    
    const changeEmail = (event: any) => {
        setEmailCode([...emailCode, event?.target?.value]);
    };

    const stepsModalHeader = () => {
        try {
            return stepsModalHeaderMapper[withdrawStep]
        } catch (e) {
            return 'Withdraw Request';
        }
    };

    const proceedWithdraw = async () => {
        if (withdrawStep === 'terms' ) {
          setWithdrawStep('password');
        }

        if (withdrawStep === 'password' && asset && networkName) {
          try {
            setLoading(true)
            const withdrawRequest = await createWithdrawRequest({
              withdrawalAmount: Number(new bigDecimal(withdrawAmount).getValue()) as unknown as string, 
              currency: asset,
              network: networkName,
              bankInfo: bankInfo  
            }).unwrap();
            setTransactionPublicUID(withdrawRequest.externalRequestUid);
            setTransactionId(withdrawRequest.id);
            const token = await passwordValidation({ password, username }).unwrap();
            setValidationToken(token);  
            await sendEmailValidationCode(withdrawRequest.id?.toString() || '').unwrap();
            setWithdrawStep('otp');
           
          } catch (e) {

          } finally {
            setLoading(false)
          }
        }
        if (withdrawStep === 'otp') {
          try {
            setLoading(true)
            const validation = { mfaToken: validationToken?.accessToken ?? '', otpCode: otpCode.join('') };
            await otpValidation({ validation, withdrawRequestId: transactionId as number}).unwrap();
            setWithdrawStep('email');
          } catch(e) {

          } finally {
            setLoading(false)
          }
        }
        if (withdrawStep === 'email') {
          setWithdrawStep('confirm');
        }
        if (withdrawStep === 'confirm') {
          try {
            setLoading(true)
            if(transactionPublicUID) {
              await emailValidation({ code: emailCode.join(''), withdrawRequestId: transactionId || 0 }).unwrap();
              toast.show({
                type: ToastType.Success,
                title: 'Your withdraw request was submitted',
                content: 'Please wait while the network processes your request, you will receive a confirmation email once the process has been completed',
                duration: 15000,
              });
              setLoading(false)
              setStartWithdrawModal(false)
              setTimeout(() => {
                navigate('/custody')
              }, 250)
            } else {
              throw new Error();
            }
            // navigate(PagesUrl.BOOST);
          } catch (e) {
            setWithdrawStep('email');
          } finally {
            setLoading(false)
          }
        }
    };

    return (
        <Modal visible={startWithdrawModal} header={stepsModalHeader()}>
        <Modal.Body>
          {withdrawStep === 'terms' && (
            <>
              <NormalSpacingBox>
                <ParagraphModal>Please confirm the withdrawal information, otherwise you may lose your funds.</ParagraphModal>
                <ParagraphModal>
                  In addition, you are required to click on the following link to review the 
                  <ExternalLink href={PrimeTerms} download='Abra Prime User Agreement' target='_blank'>
                    {' '}
                    Abra Prime User Agreement{' '}
                  </ExternalLink>{' '}
                  and “Accept Terms” to continue.
                </ParagraphModal>

                <Checkbox text='Accept Terms' checked={termsAccepted} onChange={() => clickTermsAccepted()} />
              </NormalSpacingBox>
            </>
          )}
          {withdrawStep === 'password' && (
            <>
              <NormalSpacingBox>
                <ParagraphModal>To proceed with your withdraw request, please confirm your password.</ParagraphModal>
                <br />
                <Input
                  label='Your password here'
                  inputProps={{
                    value: password,
                    type: 'password',
                    onChange: (e: any) => {
                      setPassword(e.target.value);
                    },
                  }}
                />
              </NormalSpacingBox>
            </>
          )}
          {withdrawStep === 'otp' && (
            <>
              <NormalSpacingBox>
                <ParagraphModal>Please type the code provided from your authenticator app. This is the same application you used to login.</ParagraphModal>
                <DigitVerification code={otpCode} onChange={changeOtp} onKeyDown={keyDownOtp} />
              </NormalSpacingBox>
            </>
          )}
          {withdrawStep === 'email' && (
            <>
              <NormalSpacingBox onPaste={pasteEmailCode}>
                <ParagraphModal>Please check your email, you should have received a six-digit verification code from Abra.</ParagraphModal>
                <DigitVerification code={emailCode} onChange={changeEmail} onKeyDown={keyDownEmail} digitsLength={8}/>
              </NormalSpacingBox>
            </>
          )}
          {withdrawStep === 'confirm' && (
            <>
              <ParagraphModalHeader>
                The amount will be deducted from your custody balance of <strong>{balance.getValue()}.</strong>
              </ParagraphModalHeader>
              <ParagraphSent>Transaction Preview</ParagraphSent>
              <SpacingBox>
                <LongParagraph>
                  <strong>Bank Name:</strong> {bankInfo?.bankName}
                </LongParagraph>
                <LongParagraph>
                  <strong>Bank Address:</strong> {bankInfo?.bankAddress}
                </LongParagraph>
                <LongParagraph>
                  <strong>Bank Account Number:</strong> {bankInfo?.bankAccountNumber}
                </LongParagraph>
                <LongParagraph>
                  <strong>Bank Routing Number:</strong> {bankInfo?.bankRoutingNumber}
                </LongParagraph>
                <LongParagraph>
                  <strong>Recipient Name:</strong> {bankInfo?.recipientName}
                </LongParagraph>
                <LongParagraph>
                  <strong>Recipient Address:</strong> {bankInfo?.recipientAddress}
                </LongParagraph>
                <LongParagraph>
                  <strong>SWIFT Code:</strong> {bankInfo?.swiftCode}
                </LongParagraph>
                {bankInfo?.notes && (
                  <LongParagraph>
                    <strong>MEMO:</strong> {bankInfo.notes}
                  </LongParagraph>
                )}
                <LongParagraph>
                  <strong>Amount To Be Sent: </strong> {getPriceFormattedI(asset, withdrawAmount)}
                </LongParagraph>
              </SpacingBox>
              <br></br>
              <ParagraphModal>Once you confirm, we will submit the transaction to the chosen bank account.</ParagraphModal>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button height='44' buttonType={TypesNamesEnum.SECONDAY} label='Cancel' onClick={cancelWithdraw} />
          <Button
            height='44'
            buttonType={TypesNamesEnum.ACCENT}
            label={withdrawStep === 'confirm' ? 'Confirm' : 'Next'}
            onClick={proceedWithdraw}
            disabled={!currentStepIsValid()}
            isLoading={loading}
          />
        </Modal.Footer>
      </Modal>
    )
}
const NormalSpacingBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5vh;
  border-radius: 8px;
  padding: 4px 8px;
  gap: 4px;
`;

const SpacingBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5vh;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  padding: 4px 8px;
  gap: 4px;
`;
const ParagraphModalHeader = styled.p`
  font-size: 0.8rem;
  margin-top: 3vh;
  opacity: 0.9;
`;
const ParagraphModal = styled.p`
  font-size: 0.8rem;
  opacity: 0.9;
`;
const LongParagraph = styled.p`
  font-size: 0.7rem;
  opacity: 0.8;
  word-break: break-all;
`;
const LabelInputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1vh;
  margin-bottom: 2vh;
`;
const ExternalLink = styled.a`
  color: #a399f6;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0.51px;
`;
const ParagraphSent = styled.p`
  opacity: 0.9;
  font-size: 0.8rem;
  margin-top: 3vh;
  margin-bottom: 0;
`;
