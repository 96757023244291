import React, { FC } from 'react';
import styled from 'styled-components';
import Tooltip from '../Tooltip';
import useAssets from 'shared/useAssets';

interface Props {
  quantity: string | number;
  asset?: string;
}
const TokenQuantity: FC<Props> = ({ quantity = '', asset = '' }) => {
  const {getPriceFormattedShort, getPriceFormattedI, getAssetByIdentifier} = useAssets();

  const shortAmount = getPriceFormattedShort(asset, quantity)
  const fullAmount = getPriceFormattedI(asset, quantity)
  const humanReadableBalanceAsset = getAssetByIdentifier(asset);
  const displayFormat = `${shortAmount} ${humanReadableBalanceAsset?.name}`
  
  return (
    <Tooltip text={fullAmount}>
      <TotalTokenQuantity>
        {displayFormat}
      </TotalTokenQuantity>
    </Tooltip>
  )
};

const TotalTokenQuantity = styled.p`
  max-width: 12ch;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export default TokenQuantity;
