import Layout from 'components/templates/Layout'
import React, { useEffect, useMemo, useState } from 'react'
import { MarginContainer } from './Custody'
import styled from 'styled-components'
import Widget from 'components/templates/Widget'
import { palette } from 'lib/theme'
import FormInputBox from 'components/atoms/FormInputBox'
import { Keys, useCreateKeyMutation, useDeleteKeyMutation, useLazyGetKeysQuery } from 'state/store/settingsApi'
import Loading from 'components/atoms/Loading'
import { SpinnerSizeEnum } from '@types/enums/Loading.enum'
import Button from 'components/atoms/Button'
import { SizeNamesEnum, TypesNamesEnum } from 'enums/Button.enum'
import { toast, ToastType } from 'components/organisms/Toast'
import Icon from 'components/atoms/Icon'
import { useNavigate } from 'react-router-dom'
import usePermissions from 'shared/usePermissions'
import Divider from 'components/atoms/Divider'
import { FormModal } from 'components/organisms/FormModal'
import DropdownButton, {ActionProps, DropdownOptions} from 'components/organisms/DropdownButton'
import { SettingsUser, useAddUserToOrgMutation, useChangeRolesMutation, useLazyGetUsersQuery, useRemoveUserFromOrgMutation, useResendEmailMutation, useResetMfaMutation, useResetPasswordMutation, useResetUserMfaMutation, useResetUserPasswordMutation, UsersFromOrganization } from 'state/store/api'
import { ChangeRoleModal } from 'components/organisms/ChangeRoleModal'
import { selectCurrentUser } from 'state/slice/auth.slice'
import { useSelector } from 'react-redux'
import { PagesUrl } from 'lib/constants/config.constant'

const Settings = () => {
  const [getKeys, keys] = useLazyGetKeysQuery()
  const [createKey, createdKey] = useCreateKeyMutation()
  const [deleteKey, deletedKey] = useDeleteKeyMutation()
  const [resetMfa, resetedMfa] = useResetUserMfaMutation()
  const [resetPassword, resetedPassword] = useResetUserPasswordMutation()
  const [resendEmail, resetedEmail] = useResendEmailMutation()
  const [deleteUser, deletedUser] = useRemoveUserFromOrgMutation()
  const [changeUserRole, newUserRole] = useChangeRolesMutation()
  const [getUsers, users] = useLazyGetUsersQuery()
  const [addUser, addedUser] = useAddUserToOrgMutation()

  const [currentUser, setCurrentUser] = useState<SettingsUser | null>(null)
  const [isUserLoading, setIsUserLoading] = useState<boolean>(false)
  const [changeRoleModalOpen, setChangeRoleModalOpen] = useState<boolean>(false)
  const [newKey, setNewKey] = useState<Record<string, string>>({})
  const [showModal, setShowModal] = useState<boolean>(false)
  const [approversForm, setApproversForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
  })

  const loggedInUser = useSelector(selectCurrentUser)

  const navigate = useNavigate();
  const { canSeeSettingsPage, custodySettingsEnabledLoading, canSeeApprovers } = usePermissions();
  
  useEffect(() => {
    if(!custodySettingsEnabledLoading && !canSeeApprovers.isLoading) {
      if(!canSeeSettingsPage && !canSeeApprovers.isEnabled) {
        navigate(`${PagesUrl.CUSTODY}`)
      }

      if(canSeeSettingsPage) getKeys();
      if(canSeeApprovers.isEnabled) getUsers();
    } 
  }, [canSeeSettingsPage, custodySettingsEnabledLoading, canSeeApprovers])
  
  const isLoadingKeys = useMemo(() => {
    return keys.isFetching || createdKey.isLoading || deletedKey.isLoading
  }, [keys, createdKey, deletedKey])

  const onCreateKey = async () => {
    const data = await createKey().unwrap()
    const {apiKeyInfo, apiKey} = data
    setNewKey({
      ...newKey,
      [apiKeyInfo.publicUid]: apiKey
    })
    
    getKeys()
  }

  const onDeleteKey = async (keyId?: string) => {
    if(keyId) {
      await deleteKey(keyId)
      getKeys()
    }
  }

  const onCopyKey = (value: string) => {
    navigator.clipboard.writeText(value)
    toast.show({
      type: ToastType.Success,
      title: "Value copied to clipboard!",
      content: ""
    });
  }

  const onResetMfa = async ({email, id}: ActionProps) => {
    try {
      await resetMfa(id)
      setIsUserLoading(true)
      if(!resetedMfa.error) {
        toast.show({
          type: ToastType.Success,
          title: `MFA reset for user ${email}`,
          content: ""
        });
      }
    } catch (e) {
      throw e
    } finally {
      setIsUserLoading(false)
    }
  }

  const onResendEmail = async ({email, id}: ActionProps) => {
    try{
      setIsUserLoading(true)
      await resendEmail(id)
      if(!resetedEmail.error) {
        toast.show({
          type: ToastType.Success,
          title: `Email resent to ${email}`,
          content: ""
        });
      }
    } catch (e) {
      throw e
    } finally {
      setIsUserLoading(false)
    }
  }

  const onResetPassword = async ({email, id}: ActionProps) => {
    try {
      setIsUserLoading(true)
      await resetPassword(id)
      if(!resetedPassword.error) {
        toast.show({
          type: ToastType.Success,
          title: `Password reset sent to ${email}`,
          content: ""
        });
      }
    } catch (e) {
      throw e
    } finally {
      setIsUserLoading(false)
    }
  }

  const onDelete = async ({id, email}: ActionProps) => {
    try {
      setIsUserLoading(true)
      await deleteUser(id)
      if(!deletedUser.error) {
        toast.show({
          type: ToastType.Success,
          title: `User ${email} was removed from your organization`,
          content: ""
        });
      }
    } catch (e) {
      throw e
    } finally {
      setIsUserLoading(false)
      getUsers()
    }
  }

  const changeRole = async ({id, email, role}: ActionProps) => {
    try {
      setIsUserLoading(true)
      await changeUserRole({userId: id, role: role as string})
      if(!newUserRole.error) {
        toast.show({
          type: ToastType.Success,
          title: `User ${email} was is now ${role}`,
          content: ""
        });
      }
    } catch (e) {
      throw e
    } finally {
      setIsUserLoading(false)
      getUsers()
    }
  }

  const openChangeRoleModal = async () => {
    setChangeRoleModalOpen(true)
  }

  const renderRow = (data: Keys | null, placeholder: string) => {
    const keyIsVisible = !!newKey[data?.publicUid!]
    const value = newKey[data?.publicUid!] || (data?.publicUid ? `${placeholder} (Only Visible At Time Of Creation)`: "Click Create To Generate A Key")
    return (
      <>
        <InputWrapper>
          <StyledInput disabled value={value}/> 
        </InputWrapper>
        <StyledButton 
          onClick={() => onDeleteKey(data?.publicUid)} 
          disabled={!data}
          buttonType={TypesNamesEnum.ACCENT}
          size={SizeNamesEnum.SMALL}
          label="Delete"
        />
        {keyIsVisible && 
          <Button
            onClick={() => onCopyKey(value)} 
            disabled={!newKey[data?.publicUid!]}
            buttonType={TypesNamesEnum.ACCENT}
            size={SizeNamesEnum.TINY}
          >
            <Icon name='Copy' />
          </Button>
        }
      </>
    )
  }

  const dropdownOptions: DropdownOptions[] = [
    {
      action: onDelete, 
      label:"Delete"
    },
    {
      action: onResendEmail, 
      label:"Resend email"
    },
    {
      action: onResetPassword, 
      label:"Reset password"
    },
    {
      action: onResetMfa, 
      label:"Reset MFA"
    },
    {
      action: openChangeRoleModal,
      label: "Change Role"
    }

  ]

  const renderApproversRow = (user: SettingsUser) => {
    return (
      <Row>
        <ApproversRow>
          <InputWrapper>
            <StyledInput disabled value={user?.email}/> 
          </InputWrapper>
          <InputWrapper>
            <StyledInput
              disabled
              value={user?.role}
            /> 
          </InputWrapper>
        </ApproversRow>
        <DropdownButton isDisabled={isUserLoading} items={dropdownOptions} approver={user} setCurrentUser={() => setCurrentUser(user)}/>
      </Row>
    )
  }

  const approversFormItems = [
    {
      title: "First Name",
      required: true,
      fieldName: "firstName"
    },
    {
      title: "Last Name",
      required: true,
      fieldName: "lastName"
    },
    {
      title: "Email",
      required: true,
      fieldName: "email"
    }
  ]

  const handleChangeForm = (e, field) => {
    setApproversForm({
        ...approversForm,
        [field]: e.target.value
    })
  }

  const createNewApprover = async () => {
    try {
      setIsUserLoading(true)
      await addUser({
        ...approversForm,
        organizationPublicUid: loggedInUser?.organization?.publicUid
      })
      
      if(!addedUser.error) {
        toast.show({
          type: ToastType.Success,
          title: `User ${approversForm.email} was created succesfuly`,
          content: ""
        });
        getUsers()
      }
    } catch (e) {
      throw e
    } finally {
      setIsUserLoading(false)
      setShowModal(false)
    }
  }

  return (
    <Layout>
      <MarginContainer>
        <ChangeRoleModal 
          onChangeRole={changeRole}
          user={currentUser}
          saveForm={changeRole}
          setShowModal={setChangeRoleModalOpen}
          showModal={changeRoleModalOpen}
        />
        <FormModal 
          formItems={approversFormItems}
          formValue={approversForm}
          handleChangeForm={handleChangeForm}
          saveForm={createNewApprover}
          setShowModal={setShowModal}
          showModal={showModal}
          modalTitle='Add User'
          isLoading={isUserLoading}
        />
        <Widget>
          {canSeeApprovers.isEnabled && !users.isFetching && !isUserLoading && (
            <>
              <TokenSummaryTitle>User and Role Setup</TokenSummaryTitle>
              <StyledFormInputBox>
                {users?.data?.users.map(user => {
                  return (
                    renderApproversRow(user)
                  )
                })}
                <Row>
                  <Button 
                    buttonType={TypesNamesEnum.ACCENT} 
                    size={SizeNamesEnum.SMALL} 
                    onClick={() => setShowModal(true)}
                    disabled={isUserLoading}
                  >
                    Add User
                  </Button>
                </Row>
              </StyledFormInputBox>
              <StyledDivider />
            </>
          )}
          {(users.isFetching || isUserLoading) && 
            <Loading 
              showText={false}  
              size={SpinnerSizeEnum.LARGE}
            />
          }
          {canSeeSettingsPage && (
            <>
                {!isLoadingKeys && <TokenSummaryTitle>API Key Management</TokenSummaryTitle>}
                <StyledFormInputBox>
                {!isLoadingKeys && 
                  <>
                    <Row>
                      {renderRow(keys.data ? keys.data[0] : null, "First Key")}
                    </Row>
                    <Row>
                      {renderRow(keys.data ? keys.data[1] : null, "Second Key")}
                    </Row>
                    <Row>
                      {!isLoadingKeys && (keys.data || []).length < 2 ? 
                        <Button 
                          onClick={() => onCreateKey()}
                          buttonType={TypesNamesEnum.ACCENT}
                          size={SizeNamesEnum.DEFAULT}
                          label="Create"
                        /> : 
                        <span>You can only have up to two keys</span>
                      }
                    </Row>
                  </>
                }
                {isLoadingKeys && 
                  <Loading 
                    showText={false}  
                    size={SpinnerSizeEnum.LARGE}
                  />
                }
              </StyledFormInputBox>
            </>
          )}
        </Widget>
      </MarginContainer>
    </Layout>
  )
}

const StyledDivider = styled(Divider)`
  margin: 10px 0px;
`

const ApproversRow = styled.div`
  display: flex;
  width: 90%;
`

const Row = styled.div`
  padding: 10px 0px;
  width: 100%;
  display: flex;
  justify-content: start;
`

const StyledFormInputBox = styled(FormInputBox)`
  padding: 10px 0px;
  flex-direction: column;
`;



const TokenSummaryTitle = styled.div`
  font-weight: 600;
  color: ${palette.white.main};
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 18px;
  margin-bottom: 6px;
  letter-spacing: -0.5px;
`;

const StyledInput = styled.input`
    background-color: transparent;
    color: ${palette.white.main};
    min-width: 32ch;
    width: 90%;
    border: unset;
    padding: 0px 8px 0px 8px;
    flex-grow: 1;
    display: flex;
    -moz-appearance: textfield;
    appearance: textfield;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    justify-items: center;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &:focus {
        outline: none;
    }
`;  

const InputWrapper = styled.div`
  margin-right: 12px;
  border: 1px solid ${palette.white.main};
  padding: 8px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: 90%;
  background-color: ${palette.darkBackgroundContrast.light10};
`

const StyledButton = styled(Button)`
  margin-right: 10px;
`;
export default Settings
