import Layout from "components/templates/Layout";
import React from "react";
import { useParams } from 'react-router-dom';
import { MarginContainer } from "../../Custody";
import CustodyNavigationWidget from "../../CustodyNavigationWidget";
import alternativeGridSystem from 'lib/theme/alternativeGridSystem';
import { useGetAnnualGainLossDetailsQuery, useGetAnnualGainLossSummaryQuery, useGetAnnualGainLossTotalQuery, useGetGainLossReportCSVMutation } from "state/store/reportApi";
import CustodyGainLossSummary from "./CustodyGainLossSummary";
import CustodyGainLossTransactionDetails from "./CustodyGainLossTransactionDetails";
import DownloadCsvButton from "../DownloadCsvButton";
import DocumentTitleRow from "components/molecules/DocumentTitleRow";
import styled from "styled-components";

const CustodyGainLoss: React.FC = () => {
    const { year } = useParams();
    const numericYear = year ? parseInt(year, 10) : new Date().getFullYear();
    const { data: transactions, isLoading: isDetailsLoading } =
        useGetAnnualGainLossDetailsQuery(numericYear);
    const { data: totalGainLoss, isLoading: isTotalLoading } =
        useGetAnnualGainLossTotalQuery(numericYear);
    const { data: summaryData, isLoading: isSummaryLoading } =
        useGetAnnualGainLossSummaryQuery(numericYear);
    const isLoading = isDetailsLoading || isTotalLoading || isSummaryLoading;

    return (<>
        <Layout customGrid={alternativeGridSystem}>
            <MarginContainer id='pdf-area'>
                <CustodyNavigationWidget>
                    <HeaderContainer>
                        <DocumentRowWrapper>
                            <DocumentTitleRow isLoading={isLoading} title='Gain/Loss Report' />
                        </DocumentRowWrapper>
                        <DownloadCsvButton
                            title="Gain Loss Report"
                            year={numericYear}
                            csvMutation={useGetGainLossReportCSVMutation}
                        />
                    </HeaderContainer>
                    <CustodyGainLossSummary
                        summaryData={summaryData}
                        year={numericYear}
                        totalGainLoss={totalGainLoss}
                        >
                    </CustodyGainLossSummary>
                    <CustodyGainLossTransactionDetails
                        transactions={transactions}
                        >

                    </CustodyGainLossTransactionDetails>
                </CustodyNavigationWidget>
            </MarginContainer>
        </Layout>
    </>);
};

const DocumentRowWrapper = styled.div`
    align-self: start;
    margin-right: 10px;
    width: 100%;
`

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
`;

export default CustodyGainLoss;