import Loading from 'components/atoms/Loading';
import { SpinnerSizeEnum } from 'components/atoms/Loading/Loading';
import React from 'react';
import styled from 'styled-components';
import useInvest from './useInvest';
import getUsDollar from 'shared/dollarFormat';

const InvestHeader = () => {
  const { defiBalances, totalBalance, smaAccountNumber } = useInvest();
  const loadingBalance = defiBalances.isLoading;
  return (
    <div>
      <Header>
        <PortfolioView>Portfolio View</PortfolioView>
      </Header>
      <CurrentBalanceTitle>Current Invested Balance</CurrentBalanceTitle>
      {loadingBalance && (
        <LoadingArea>
          <Loading size={SpinnerSizeEnum.LARGE} showText={false} />
        </LoadingArea>
      )}
      {!loadingBalance && (
        <>
          <CurrentBalanceValue>{getUsDollar(totalBalance)}</CurrentBalanceValue>
          <SMAAccountTitle>SMA Account Number</SMAAccountTitle>
          <SMAAccountValue>{smaAccountNumber ?? '-'}</SMAAccountValue>
        </>
      )}
    </div>
  );
};

const LoadingArea = styled.div`
  margin-top: 3vh;
`;
const SMAAccountTitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
`;

const SMAAccountValue = styled.p`
  font-weight: 400;
  color: #ffffff;
  font-size: 18px;
  letter-spacing: -0.48px;
  margin-bottom: 30px;
`;

const PortfolioView = styled.h2`
  font-weight: 600;
  color: #ffffff;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: -0.5px;
  margin-bottom: 30px;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
const CurrentBalanceTitle = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
`;

const CurrentBalanceValue = styled.p`
  color: #ffffff;
  font-size: 48px;
  font-weight: normal;
  letter-spacing: -0.025rem;
  margin-bottom: 12px;
`;

export default InvestHeader;
