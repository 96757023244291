import React, { useEffect, useMemo, useState, ChangeEvent } from 'react';
import styled from 'styled-components';
import CustodyNavigationWidget from '../CustodyNavigationWidget';
import Layout from 'components/templates/Layout';
import useCustodyRedirect from '../extraCustodyHooks/useCustodyRedirect';
import Widget from 'components/templates/Widget';

import { Step } from '../fiat/BankWireSteps';
import FormInputBox from 'components/atoms/FormInputBox';
import { ValueInput } from 'components/atoms/ValueInput/ValueInput';
import { palette } from 'lib/theme';
import Button from 'components/atoms/Button';
import { SizeNamesEnum, TypesNamesEnum } from 'enums/Button.enum';
import Modal from 'components/organisms/Modal';
import Row from 'components/atoms/Row/Row';
import {
  BankInfo,
  useLazyGetWithdrawInfoQuery,
} from 'state/store/fiatWithdrawApi';
import bigDecimal from 'js-big-decimal';
import Column from 'components/atoms/Column/Column';
import LoadingStage from 'pages/LoadingStage';
import Tooltip from 'components/atoms/Tooltip';
import { useLazyGetBankInfoQuery } from 'state/store/custodyApi';
import { useSearchParams } from 'react-router-dom';
import { WithdrawConfirmModal } from 'pages/Withdraw/WithdrawConfirmModal';
import useAssets from 'shared/useAssets';

const FiatWithdraw: React.FC = () => {
  const [params] = useSearchParams();
  const [getWithdrawInfo, withdrawInfo] = useLazyGetWithdrawInfoQuery();
  const [getBankInfoRequest, bankInfo] = useLazyGetBankInfoQuery();
  const { getPriceFormattedI } = useAssets();

  const [minError, setMinError] = useState(false);
  const [maxError, setMaxError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState<string>('0');
  const [withdrawForm, setWithdrawForm] = useState<ExtendedBankInfo>({
    recipientName: '',
    recipientAddress: '',
    bankName: '',
    bankAddress: '',
    bankRoutingNumber: '',
    bankAccountNumber: '',
    swiftCode: '',
    notes: '',
  });

  const handleWithdrawAmountChange = (value: string): void => {
    setWithdrawAmount(new bigDecimal(value).stripTrailingZero().getValue());
  };

  const withdrawAmounts = useMemo(() => {
    const zeroAmount = new bigDecimal(0);

    const amounts: Amounts = {
      max: zeroAmount,
      min: zeroAmount,
      available: zeroAmount,
      fixedWireFee: '',
      variableConversionFee: '',
      variableConversionFeeBps: 0,
      estimatedNetworkFee: '',
    };

    if (withdrawInfo.data) {
      const {
        maxWithdrawalAmount,
        minWithdrawalAmount,
        availableAmountInAsset,
        fixedWireTransferFee,
        variableConversionFeeBps,
        estimatedNetworkFee,
      } = withdrawInfo.data;

      amounts.max = new bigDecimal(maxWithdrawalAmount.amount);
      amounts.min = new bigDecimal(minWithdrawalAmount.amount);
      amounts.available = new bigDecimal(availableAmountInAsset.amount);
      amounts.variableConversionFeeBps = variableConversionFeeBps;

      const variableFee = new bigDecimal(withdrawAmount || 0).multiply(
        new bigDecimal(variableConversionFeeBps / 10000)
      );

      amounts.fixedWireFee = getPriceFormattedI(
        fixedWireTransferFee.asset,
        fixedWireTransferFee.amount
      );
      amounts.variableConversionFee = getPriceFormattedI(
        params.get('asset') || '',
        variableFee.getValue()
      );
      amounts.estimatedNetworkFee = getPriceFormattedI(
        estimatedNetworkFee.asset,
        estimatedNetworkFee.amount
      );
    }
    return amounts;
  }, [withdrawInfo, withdrawAmount, getPriceFormattedI, params]);

  const setMax = (): void => {
    if (withdrawAmounts.max.compareTo(new bigDecimal(0)) > 0) {
      setWithdrawAmount((withdrawAmounts.max.stripTrailingZero().getValue()));
    }
  };

  const handleCloseModal = (): void => {
    setShowModal(false);
  };

  const handleOpenModal = (): void => {
    setShowModal(true);
  };

  const handleChangeForm = (
    e: ChangeEvent<HTMLInputElement>,
    field: keyof BankInfo
  ): void => {
    setWithdrawForm({
      ...withdrawForm,
      [field]: e.target.value,
    });
  };

  useCustodyRedirect();

  const renderLoader = (): JSX.Element => {
    return (
      <Row>
        <LoadingStage loadingText=' ' />
      </Row>
    );
  };

  const withdrawFormItems: WithdrawTableData[] = [
    {
      title: 'Recipient Name',
      required: true,
      fieldName: 'recipientName',
    },
    {
      title: 'Recipient Address',
      required: true,
      fieldName: 'recipientAddress',
    },
    {
      title: 'Bank Name',
      required: true,
      fieldName: 'bankName',
    },
    {
      title: 'Bank Address',
      required: true,
      fieldName: 'bankAddress',
    },
    {
      title: 'Bank Routing Number',
      required: true,
      fieldName: 'bankRoutingNumber',
    },
    {
      title: 'Bank Account Number',
      required: true,
      fieldName: 'bankAccountNumber',
    },
    {
      title: 'SWIFT Code',
      required: false,
      fieldName: 'swiftCode',
    },
    {
      title: 'Memo/ Notes',
      required: false,
      fieldName: 'notes' as keyof BankInfo,
    },
  ];

  const allRequiredFieldsAreSet = (): boolean => {
    const {
      swiftCode,
      notes,
      bankTransactionId,
      bankTransactionPublicUID,
      ...requiredFields
    } = withdrawForm;
    return Object.values(requiredFields).every(
      (requiredField) => requiredField.trim() !== ''
    );
  };

  useEffect(() => {
    getBankInfoRequest();
  }, []);

  useEffect(() => {
    getWithdrawInfo({
      currency: params.get('asset') || '',
      network: params.get('network') || '',
    });
  }, [params]);

  useEffect(() => {
    if (bankInfo && bankInfo.data) {
      const incomingWithdrawInfo: ExtendedBankInfo = {
        recipientName: '',
        recipientAddress: '',
        bankName: '',
        bankAddress: '',
        bankRoutingNumber: '',
        bankAccountNumber: '',
        swiftCode: '',
        notes: '',
      };

      Object.entries(bankInfo.data as BankInfo).forEach(([key, value]) => {
        incomingWithdrawInfo[key] = `${value}`;
      });

      setWithdrawForm(incomingWithdrawInfo as BankInfo);
    }
  }, [bankInfo]);

  useEffect(() => {
    setMinError(
      (withdrawAmount !== '0' &&
        new bigDecimal(withdrawAmount).compareTo(withdrawAmounts.min) < 0) ||
        withdrawAmount === '0'
    );
    setMaxError(
      new bigDecimal(withdrawAmount).compareTo(withdrawAmounts.max) > 0
    );
  }, [withdrawAmount, withdrawAmounts]);

  return (
    <Layout>
      <Modal visible={showModal} onClose={() => handleCloseModal()}>
        <Row justifyContent='center'>
          {`The amount of ${withdrawAmount} USD will be credited to the following account`}
        </Row>
        <BankInfoReview
          rows={withdrawFormItems}
          handleChangeForm={handleChangeForm}
          withdrawForm={withdrawForm}
          forceDisabled
        />
        <Row style={{ padding: 10 }} justifyContent='space-between'>
          <Button
            buttonType={TypesNamesEnum.SECONDAY}
            size={SizeNamesEnum.DEFAULT}
            onClick={() => handleCloseModal()}
          >
            Back
          </Button>
          <Button
            buttonType={TypesNamesEnum.ACCENT}
            size={SizeNamesEnum.DEFAULT}
            onClick={() => setShowReviewModal(true)}
          >
            Confirm
          </Button>
        </Row>
      </Modal>
      <WithdrawConfirmModal
        bankInfo={withdrawForm}
        asset={params.get('asset') || undefined}
        networkName={params.get('network')}
        withdrawAmount={withdrawAmount}
        balance={withdrawAmounts.available}
        startWithdrawModal={showReviewModal}
        setStartWithdrawModal={() => setShowReviewModal(false)}
      />
       <PrintableDix>
        <CustodyNavigationWidget>
        {(withdrawInfo.isLoading || bankInfo.isLoading) ? (
            renderLoader()
        ) : (
            <div>
          <Header>
            <Title>Withdraw USD</Title>
          </Header>
          <Widget>
            <Step number={1}>Enter Bank Wire Information</Step>
            <BankInfoTable
              rows={withdrawFormItems}
              handleChangeForm={handleChangeForm}
              withdrawForm={withdrawForm}
            />
          </Widget>
          <Widget>
            <Step number={2}>Select the amount you want to withdraw</Step>
            <Row style={{ padding: '10px 45px' }}>
              <FormInputBoxStyled>
                <ValueInput
                  assetName={'USD'}
                  changeInvestmentAmount={handleWithdrawAmountChange}
                  investmentAmount={withdrawAmount}
                  renderMaxButton={true}
                  setMax={() => setMax()}
                  error={minError || maxError}
                />
                <span>{`Available amount you can withdraw is ${withdrawAmounts.available.getValue()}`}</span>
                <span>{`Minimum amount you can withdraw is ${withdrawAmounts.min.getValue()}`}</span>
              </FormInputBoxStyled>
            </Row>
          </Widget>
          <Widget>
            <Step number={3}>Fees</Step>
            <Column style={{ padding: '10px 45px' }}>
              <Row>Following fees will apply to this transaction:</Row>
              {withdrawInfo.isLoading
                ? renderLoader()
                : withdrawInfo.data && (
                    <Row style={{ padding: 20 }}>
                      <ul>
                        <StyledLi>
                          Fixed Wire Fee: {withdrawAmounts.fixedWireFee}
                        </StyledLi>
                        <StyledLi>
                          Variable Conversion Fee:{' '}
                          {withdrawAmounts.variableConversionFee} ({withdrawAmounts.variableConversionFeeBps} bps)
                        </StyledLi>
                        <StyledLi>
                          Estimated Network Fee:{' '}
                          {withdrawAmounts.estimatedNetworkFee}
                        </StyledLi>
                      </ul>
                    </Row>
                  )}
            </Column>
          </Widget>
          <Row
            style={{
              padding: '20px 0',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Tooltip
              text='All required fields and value needs to be set!'
              visible={!allRequiredFieldsAreSet() || minError || maxError}
            >
              <div>
                <Button
                  height='48'
                  buttonType={TypesNamesEnum.ACCENT}
                  onClick={() => handleOpenModal()}
                  size={SizeNamesEnum.DEFAULT}
                  disabled={!allRequiredFieldsAreSet() || minError || maxError}
                >
                  Review
                </Button>
              </div>
            </Tooltip>
          </Row>
          </div> )}
        </CustodyNavigationWidget>
      </PrintableDix>
   
    </Layout>
  );
};

const BankInfoTable: React.FC<BankInfoTableProps> = React.memo(
  ({ data, rows, handleChangeForm, withdrawForm, forceDisabled }) => (
    <Table>
      <tbody>
        {rows.map((row) => (
          <tr key={row.title}>
            <th>
              {formatKey(row.title)} {row.required && '*'}
            </th>
            <CopyText>
              <FormInputTable>
                <InputAmount
                  value={withdrawForm[row.fieldName]}
                  placeholder={row.title}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeForm(e, row.fieldName as keyof BankInfo)
                  }
                  disabled={forceDisabled}
                />
              </FormInputTable>
            </CopyText>
          </tr>
        ))}
      </tbody>
    </Table>
  )
);

const BankInfoReview: React.FC<BankInfoTableProps> = React.memo(
  ({ data, rows, handleChangeForm, withdrawForm, forceDisabled }) => (
    <ReviewTable>
      <tbody>
        {rows.map((row) => (
          <tr key={row.title}>
            <th>{formatKey(row.title)}</th>
            <CopyText>
              <FormInputTable>
                <InputAmount
                  value={withdrawForm[row.fieldName]}
                  placeholder={row.title}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeForm(e, row.fieldName as keyof BankInfo)
                  }
                  disabled={forceDisabled}
                />
              </FormInputTable>
            </CopyText>
          </tr>
        ))}
      </tbody>
    </ReviewTable>
  )
);

interface Amounts {
  max: bigDecimal;
  min: bigDecimal;
  available: bigDecimal;
  fixedWireFee: string;
  variableConversionFee: string;
  variableConversionFeeBps: number;
  estimatedNetworkFee: string;
}

interface WithdrawTableData {
  title: string;
  required: boolean;
  fieldName: string;
}

interface BankInfoData {
  recipientName: string;
  routingNumber: string;
  bankName: string;
  accountNumber: string;
  bankAddress: string;
  recipientAddress: string;
}

interface BankInfoTableProps {
  data?: BankInfoData;
  rows: WithdrawTableData[];
  handleChangeForm: (
    e: ChangeEvent<HTMLInputElement>,
    field: keyof BankInfo
  ) => void;
  withdrawForm: BankInfo;
  forceDisabled?: boolean;
}

interface ExtendedBankInfo extends BankInfo {
  bankTransactionId?: string;
  bankTransactionPublicUID?: string;
  [key: string]: any;
}

const formatKey = (key: string): string => {
  return key
    .split(/(?=[A-Z])/)
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const CopyText = styled.td`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  cursor: pointer;
`;

const PrintableDix = styled.div`
  @media print {
    a,
    p,
    span,
    div {
      color: black !important;
    }
  }
`;

const Header = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const InputAmount = styled.input.attrs({
  type: 'text',
})`
  text-align: center;
  border: 1px solid white;
  border-radius: 4px;
  background-color: transparent;
  color: ${palette.white.main};
  min-width: 32ch;
  width: 100%;
  padding: 10px 0px;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  -moz-appearance: textfield;
  appearance: textfield;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus {
    outline: none;
  }
`;

const StyledLi = styled.li`
  padding: 10px;
`;

const Title = styled.div`
  font-weight: 600;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  font-size: 35px;
`;

const Table = styled.table`
  width: calc(100% - 3rem);
  border-collapse: collapse;
  margin-top: 10px;
  margin-left: 3rem;
  border: none;

  th,
  td {
    padding: 8px;
    text-align: left;
    vertical-align: middle;
  }

  th {
    width: 30%;
    font-weight: bold;
  }
`;

const ReviewTable = styled(Table)`
  margin-left: 1.3rem;
  border-collapse: collapse;
  border: none;

  th,
  td {
    padding: 8px;
    text-align: left;
    vertical-align: middle;
  }

  th {
    width: 30%;
    font-weight: bold;
  }
`;

const FormInputTable = styled(FormInputBox)`
  width: 100%;
`;

const FormInputBoxStyled = styled(FormInputBox)`
  flex-direction: column;
  align-items: baseline;
`;

export default FiatWithdraw;
